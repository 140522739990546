import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Two weeks have passed and the community has had some time to consolidate. There are a lot of new faces and new contributors from different regions. After having to stop Conviction Voting due to a misconfiguration these last two weeks were a blessing in disguise that gave us breathing space after the explosion of growth we had.`}</p>
    <p>{`The process to submit new proposals was updated and a new social expectation started to grow towards better moderation. The documentation grew significantly with community members jumping to create onboarding guides, FAQ, video tutorials and translations. The Discord server was reorganized with different roles for each swarm to improve our daily communications.`}</p>
    <p>{`The pollen distribution using SourceCred is a success, new community members are excited about being rewarded for their contributions. We have a `}<a parentName="p" {...{
        "href": "https://forum.1hive.org/t/new-pollen-proposal-for-period-starting-oct-23/407/"
      }}>{`forum post`}</a>{` with a proposal to keep funding the initiative in future weeks that saw a lot of engagement and discussion.`}</p>
    <h2 {...{
      "id": "when-farming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-farming",
        "aria-label": "when farming permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`When farming?`}</h2>
    <div {...{
      "style": {
        "textAlign": "center",
        "marginBottom": "24px"
      }
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "256px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fff7f0d4cc172e7b288a922599797343/e4a55/when.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "163.28125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGOiazLuYJz6Yd83vKORuE7gyf/xAAeEAADAAEEAwAAAAAAAAAAAAABAgMABBETMRIUIf/aAAgBAQABBQLcGkdQk09qeXA5dO6AMU3+NWNZohtHGVQqS8s4TlepdL1//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwFf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwFf/8QAIxAAAQMCBQUAAAAAAAAAAAAAAAECETFBIjJRcaEQEiORov/aAAgBAQAGPwJy+iHIqmVSdbERi2MnA7Sx5K7FPkavdiWxKvbBTgYJ0//EAB8QAAIDAAICAwAAAAAAAAAAAAERACExEEFRoXHB8f/aAAgBAQABPyHLFkMSH0vuH8KWGiNpkbMHfIJIaFQmA229cOdjqUSlYCWDueEV8J6E948f/9oADAMBAAIAAwAAABBv2w70H//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBExIf/aAAgBAwEBPxB5DfCx5H//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAgEBPxBFEukFn//EAB8QAQADAAICAwEAAAAAAAAAAAEAESExQVGxYXGh8P/aAAgBAQABPxBsZfUsDMgm71IVHhhiVx9g9TaElSijJuX2w5lRhq6twbbHHQbrsQOn7gwN4FbTq7IlHedU9rDEoFiL/PcIOm0bT5zmVY0YPJP3vc/neZxz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "when",
            "title": "when",
            "src": "/static/fff7f0d4cc172e7b288a922599797343/e4a55/when.jpg",
            "srcSet": ["/static/fff7f0d4cc172e7b288a922599797343/e4a55/when.jpg 256w"],
            "sizes": "(max-width: 256px) 100vw, 256px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
  `}<span parentName="div" {...{
        "style": {
          "fontStyle": "italic",
          "fontSize": "15px"
        }
      }}>{`
    Cred to Detzy for
    `}<a parentName="span" {...{
          "href": "https://discord.com/channels/698287700834517064/743890898781339658/764545821651763232"
        }}>{`
      this amazing meme
    `}</a>{`
  `}</span>
    </div>
    <p>{`The conviction voting smart contract issue was fixed and we have everything ready to launch the HNY farming program!`}</p>
    <p>{`We have created a `}<a parentName="p" {...{
        "href": "https://forum.1hive.org/t/honeycomb-liquidity-incentives/488"
      }}>{`forum post`}</a>{` and 3 proposals to fund the 3 main pools to kickstart the farming which are the following:`}</p>
    <ul>
      <li parentName="ul">{`“Dai Daisies” 🌼 pool that holds the `}<inlineCode parentName="li">{`HNY-WXDAI`}</inlineCode>{` pair with 300 HNY`}</li>
      <li parentName="ul">{`“STAKE Sunflower” 🌻 pool that holds the `}<inlineCode parentName="li">{`HNY-STAKE`}</inlineCode>{` pair with 200 HNY`}</li>
      <li parentName="ul">{`“Ether Eucalyptus” 🌿 pool that holds the `}<inlineCode parentName="li">{`WETH-HNY`}</inlineCode>{` pair with 100 HNY`}</li>
    </ul>
    <p>{`Liquidity incentives have proved to be an excellent tool to attract more participants to a network and grow the liquidity of assets involved. For the 1hive community and the Honey token the incentives play a key role to keep experimenting with circular economies. Honeyswap success is coupled to the Honey economy as a portion of every swap fee flows back to the common pool to fund further community proposals.`}</p>
    <p>{`Anyone can champion a proposal to fund a new pool on `}<a parentName="p" {...{
        "href": "https://hny.farm/"
      }}>{`hny.farm`}</a>{`. We encourage everyone to create a forum post and start a discussion there about why you think it may be a good fit.`}</p>
    <p>{`Let the farming games begin!`}</p>
    <h2 {...{
      "id": "honeypot-v2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#honeypot-v2",
        "aria-label": "honeypot v2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Honeypot v2`}</h2>
    <p>{`Version 2 of the Honeypot is coming along next week with a fresh install of the conviction voting app, this is a huge user experience enhancement that includes users profiles and a renovated proposal feed!`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "marginBottom": "24px"
      }
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3e5c9cbfe8978b9fe75a87691e798ae4/d9199/feed.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB3klEQVQoz22Q628SQRRH91/WqIl9CNVGjfGLKVGjtsbESE3VUi2PGoRdJIJfbGoptQqIEWgL3ba8FnYXON5ZQW3ibE727tz5nZkdzXXrTBiNjjg5KWMYYXR9nVQq4mFMUPNjVG2aJcmY/OvQJoVtHwJtEYUJra2KKEXCMIgbSdLv06TTaQxdl36SbOYDodAa4fCKZDqSPfgrHAwaKBxHCVuyc1R2NpkMZ+BwdgzlsTky60QiLzyhI4eZeLTdfhFF3ipQokIw+ZJqpSJNF6tn0bQ69F0Hx3UZugMavVOKvTrlSpml9ad8p8aO9Y2JR8taX1BkOrtsSnvx3XMO9vflbob0+j1OWy36dl/+QKS2Q8kx+cQhuZ8F7r55IpmyZPOe46OgpawdFHonR2ZU4H48SK1alRPZdLsdjtstuiKzlVD4YZnsOQ3y5QJ3Xj8mSxG9neO3J48W6WyhCLc2ibPHgh6k3Wr+uTF7YPO/0TSPuRVeJMFXL6scUUGLdT8TUx/tLTa628w9C3DxwmX8/nlmr1xlatrHzJQg79kZv8zN4Zub5/y5S0wv3eatte1llUO5tOjYHJPGai1DILnMjdAjrr96KDzg2so9fMEF/MGAV6s51bspawKJZS+zoaRj4S/TZnTZKEn7pgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "feed",
            "title": "feed",
            "src": "/static/3e5c9cbfe8978b9fe75a87691e798ae4/d9199/feed.png",
            "srcSet": ["/static/3e5c9cbfe8978b9fe75a87691e798ae4/5a46d/feed.png 300w", "/static/3e5c9cbfe8978b9fe75a87691e798ae4/0a47e/feed.png 600w", "/static/3e5c9cbfe8978b9fe75a87691e798ae4/d9199/feed.png 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`It will also involve a new installation of the smart contract so all the existing proposals at that point will be void. We encourage the members of the community to take this week to write and start discussing the proposals on the `}<a parentName="p" {...{
        "href": "https://forum.1hive.org"
      }}>{`forum`}</a>{` using the `}<a parentName="p" {...{
        "href": "https://forum.1hive.org/new-topic?category=Proposals"
      }}>{`new template`}</a>{` so we can have high quality proposals on the new version of honey pot.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      